<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div 
          slot="no-body" 
          class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <vs-progress
              v-if="loading"
              indeterminate
              color="primary"
            />
            <div class="vx-col center-div p-12">
              <img
                v-anime="{
                  rotate: '1turn',
                  duration: 2000,
                  loop: !loading,
                }"
                :src="require('@/assets/images/logo/152x152.png')"
                class="center"
              >

              <h4 class="mb-4 mt-6 text-center">Welcome to Express POS</h4>
              <p class="text-center pt-6">
                Thank you for installing our point of sale system. The fastest,
                cleanest and most secure in the market to handle your everyday
                business transactions.
              </p>
              <vs-button
                v-if="!loading"
                class="flex flex-wrap mt-8 center"
                @click="next"
              >Start</vs-button
              >
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { KinesisContainer, KinesisElement } from "vue-kinesis";

export default {
  components: {
    KinesisContainer,
    KinesisElement,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    next() {
      this.loading = true;
      setTimeout(() => {
        this.$router.push({ name: "install" });
      }, 2000);
    },
  },
};
</script>

<style lang="scss">
.center-div {
  position: relative;
  width: 100%;
  height: 100%;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
